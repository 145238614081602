<template>
  <Layout>
    <PageHeader :title="title" moda_title="Datos Usuario" ref="pageForm" :isAdmin="isItForAdmin()">
      <template>
        <PageForm :typeform="typeform" @closeModa="closeModa" :clients="clients" />
      </template>
    </PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <Tooltip>
                <p>
                  En este modulo podras editar la informacion de inicio de
                  sesion de los usuarios, y sus roles.
                </p>
                <p>
                  Además puedes cambiar el estatus del usuario para que no pueda
                  ingresar en el sistema
                </p>
              </Tooltip>
            </div>
            <div class="row mt-4">
              <div class="col-md-3 col-sm-9">
                <label class="d-inline-flex align-items-center w-100">
                  <span class="m-2">{{ $t('filter.from') }}: </span>
                  <b-form-input v-model="filterData.fromDate" type="date" class="form-control-sm"></b-form-input>
                </label>
              </div>
              <div class="col-md-3 col-sm-9">
                <label class="d-inline-flex align-items-center w-100">
                  <span class="m-2">{{ $t('filter.until') }}: </span>
                  <b-form-input v-model="filterData.toDate" type="date" class="form-control-sm"></b-form-input>
                </label>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-4" v-if="checkAdmins()">
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t('filter.client') }}:
                  <select v-model="filterData.clientId" @change="change" name="client"
                    class="form-select form-select-sm m-2 border-0 shadow-lg" placeholder="Seleccione..">
                    <option disabled selected>{{ $t('helpers.select') }}...</option>
                    <option v-for="({ _id, name }, index) in clients" :key="index" :value="_id">
                      {{ name }}
                    </option>
                  </select>
                </label>
              </div>
              <div class="col-sm-12 col-md-4" v-if="userRole !== 'OPERATOR'">
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t('filter.operator') }}:
                  <select v-model="filterData.operatorId" @change="change" name="client"
                    class="form-select form-select-sm m-2 border-0 shadow-lg" placeholder="Seleccione..">
                    <option disabled selected>{{ $t('helpers.select') }}...</option>
                    <option v-for="(item, index) in operators" :key="index" :value="item._id">
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div>
            </div>
            <div class="d-flex align-items-center mt-2">
              <vs-button @click="getData">{{ $t('filter.filter') }}</vs-button>
              <vs-button success @click="cancelFilter()" :disabled="!Object.entries(filterData).length">
                {{ $t('form.abort') }}
              </vs-button>
            </div>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    {{ $t('filter.show') }}&nbsp;
                    <vs-select v-model="perPage" color="dark">
                      <vs-option v-for="(op, index) in pageOptions" :key="index" :label="op" :value="op">{{ op
                        }}</vs-option>
                    </vs-select>
                    &nbsp;{{ $t('filter.entries') }}
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    {{ $t('filter.search') }}:
                    <vs-input v-model="filter" type="search" primary></vs-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0" v-if="tableData.length">
              <b-table :items="tableData" :fields="makeFields()" responsive="sm" :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn">
                <!-- Column: Actions -->
                <template #cell(actions)="data">
                  <div class="action-btn-container">
                    <b-dropdown variant="link" no-caret class="position-absolute">
                      <template #button-content>
                        <i class="fa fa-fw fa-bars font-size-16" />
                      </template>
                      <b-dropdown-item :to="{
                        name: 'user-show',
                        params: { id: data.item._id },
                      }">
                        <i class="uil-file-alt m-2 font-size-18" />
                        <span class="align-middle ml-5">{{ $t('actions.subItems.details') }}</span>
                      </b-dropdown-item>

                      <!-- <div>
                        <b-dropdown-item @click="edit(data.item)">
                          <i class="uil-edit m-2 font-size-18" />
                          <span class="align-middle ml-5">Editar</span>
                        </b-dropdown-item>
                      </div> -->

                      <div hidden="">
                        <b-dropdown-item @click="remove(data.item._id)">
                          <i class="mdi mdi-delete-outline m-2 font-size-18" />
                          <span class="align-middle ml-5">{{ $t('actions.subItems.delete') }}</span>
                        </b-dropdown-item>
                      </div>
                      <div>
                        <b-dropdown-item @click="changeEstatus(data.item)">
                          <i class="mdi mdi-check-outline m-2 font-size-18" />
                          <span class="align-middle ml-5">{{ $t('actions.subItems.status') }}</span>
                        </b-dropdown-item>
                      </div>
                      <div>
                        <b-dropdown-item @click="edit(data.item)">
                          <i class="uil-pen m-2 font-size-18" />
                          <span class="align-middle ml-5">{{ $t('actions.subItems.edit') }}</span>
                        </b-dropdown-item>
                      </div>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
            </div>
            <div v-else>{{ $t('helpers.noInfo') }}</div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <!-- <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination> -->
                    <button class="btn btn-secondary mx-2 uil-angle-left" @click="beforePage"
                      :disabled="!skip"></button>
                    <button class="btn btn-primary mx-2 uil-angle-right" @click="nextPage"
                      :disabled="tableData.length < limit"></button>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import PageForm from "./form.vue";
import Swal from "sweetalert2";
import Tooltip from "../../../components/tooltip.vue";
import { onlyAdmins } from "../../../router/guards/roles";
import { mapActions, mapGetters } from "vuex";

/**
 * Users-table component
 */
export default {
  page: {
    title: "Users Table",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, PageForm, Tooltip },
  data() {
    return {
      tableData: [],

      title: this.$t('users.title'),
      items: [
        {
          text: "Tables",
        },
        {
          text: "Usuarios",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: ["userName"],
      clients: [],
      operators: [],
      sortBy: "age",
      sortDesc: false,
      fields: [],
      userSave: {},

      typeform: {
        name: "",
        lastName: "",
        userName: "",
        password: "",
        email: "",
      },
      filterData: {},

      limit: 10,
      skip: 0,
      userRole: "",
      userData: "",

      readAllRoles: onlyAdmins
    };
  },
  computed: {
    /**
     * Total no. of records
     */

    rows() {
      return this.tableData.length;
    },
    ...mapGetters({
      users: 'user/getUsers'
    })
  },
  mounted() {
    // this.getData();
    // this.getClients();
    this.getUserRole();
  },
  methods: {
    isItForAdmin() {
      return ["ADMIN"].includes(this.userRole);
    },
    makeFields() {
      const globalFields = [
        {
          key: "name",
          sortable: true,
          label: this.$t('users.name').toUpperCase(),
        },
        {
          key: "lastName",
          sortable: true,
          label: this.$t('users.lastName').toUpperCase(),
        },
        {
          key: "userName",
          sortable: true,
          label: this.$t('users.userName').toUpperCase(),
        },
        {
          key: "email",
          sortable: true,
          label: this.$t('users.email').toUpperCase(),
          formatter: (value) => {
            return value ? value : "NO EMAIL";
          },
        },
        {
          key: "status",
          sortable: true,
          label: this.$t('users.status').toUpperCase(),
          formatter: (value) => {
            return value ? this.$t('helpers.isActive') : "Bloqueado";
          },
        },
        // {
        //   key: "userClient",
        //   sortable: true,
        //   label: "Categoria",
        //   formatter: (value) => {
        //     return value ? "Cliente" : "Normal";
        //   },
        // },
        {
          key: "userRole[0].name",
          sortable: false,
          label: this.$t('users.role').toUpperCase(),
          formatter: (value) => {
            return value ? value : "NO ROLE";
          },
        },
      ];

      const adminFields = [
        {
          key: "actions",
          sortable: false,
          label: this.$t('actions.text').toUpperCase(),
        },
      ];

      if (this.userRole === "ADMIN") {
        const fields = [...globalFields, ...adminFields];

        return fields;
      }

      return globalFields;
    },
    nextPage() {
      this.skip += this.perPage;
      this.currentPage++;
      this.getData();
    },
    beforePage() {
      this.skip -= this.perPage;
      this.currentPage--;

      this.getData();
    },
    cancelFilter() {
      this.filterData = {};
      this.getUserRole();
    },
    change() {
      this.getData();
    },
    checkAdmins() {
      return this.readAllRoles.includes(this.userRole);
    },
    getUserRole() {
      this.userData = this.$store.getters["user/getUserData"];
      this.userRole = this.userData.role.name;

      if (this.userRole === "CLIENT") {
        this.filterData.clientId = this.userData.client;
        this.getOperatorClient();
      } else if (this.userRole === "OPERATOR") {
        this.filterData.operatorId = this.userData.operator;
      } else {
        this.getClients();
        this.getOperator();
      }
      this.getData();
    },
    getOperatorClient() {
      const clientId = this.userData.client;
      this.$http
        .get(`/operators?client=${clientId}`)
        .then(({ data }) => {
          if (data.ok) {
            this.operators = data.operators;
          }
        })
        .catch((err) => {
          console.log("Error al obtener operadores del cliente", err);
        });
    },
    getClients() {
      this.$http
        .get("/clients")
        .then((response) => {
          this.clients = response.data.clients;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getOperator() {
      this.$http
        .get("/operators")
        .then((response) => {
          this.operators = response.data.operators;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getData() {
      this.loading = true;

      await this.fetchUsers({
        limit: this.limit,
        page: this.currentPage,
        ...this.filterData,
      })

      this.tableData = [...this.users]
      this.loading = false;
    },
    closeModa() {
      this.$refs.pageForm.modal = false;
      this.typeform = {
        name: "",
        lastName: "",
        userName: "",
        password: "",
        email: "",
      };
      this.getData();
    },
    edit(item) {
      this.typeform = { ...item };
      if (item.client) this.typeform.client = item.client._id;
      this.$refs.pageForm.modal = true;
    },
    async changeEstatus(item) {
      let text = "";
      let status = 0;
      if (item.status == true) {
        text = "bloquear";
        status = false;
      } else {
        text = "desbloquear";
        status = true;
      }

      const result = await Swal.fire({
        title: "Esta de acuerdo de " + text + " el usuario?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Si",
        denyButtonText: "No",
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
          denyButton: "order-3",
        },
      });
      if (result.isConfirmed) {
        const { data } = await this.$http.put(`/users/${item._id}`, {
          status: status,
        });

        if (!data.ok) {
          return Swal.fire("Error actualizando usuario", "", "error");
        }

        Swal.fire("Usuario actualizado.", "", "success");
        return this.getUserRole();
      } else if (result.isDenied) {
        Swal.fire("Tiene oportunidad de verificar la información", "", "info");
      }
    },
    remove(id) {
      this.$http
        .delete(`users/${id}`)
        .then(() => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Usuario eliminado con exito",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    ...mapActions({
      fetchUsers: 'user/fetchUsers'
    })
  },
  watch: {
    perPage() {
      this.skip = 0;
      this.limit = this.perPage;
      this.getData();
    },
  },
  middleware: "authentication",
};
</script>

<style scoped>
.action-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 120px;
  padding: 10px;
}
</style>
